.bio {
  margin-left: 16px;
  font-size: 16px;
  text-align: justify;

  @media (max-width: 768px) {
    margin-left: 0;

    /*Style for team member's mobile display*/
    .Avatar {
      gap: 10px;

      .title {
        padding: 2px 0px;
        margin-top: 0px;
      }
    }
  }
}
