@font-face {
  font-family: "lato";
  src: url("../public/fonts/lato-regular.woff") format("woff"),
    url("../public/fonts/lato-regular.ttf") format("truetype");
}

* {
  font-family: "Open Sans";
}

body {
  background: white;
}

a {
  text-decoration: none;
}
