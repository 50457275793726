.header {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: calc(100vh - 112px);

  @media screen and (max-width: 768px) {
    padding-top: 80%;
  }

  img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: linear-gradient(to top, #191412, #100c0b) center/cover no-repeat
      fixed;
    background-position: center;
  }

  .banner {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
