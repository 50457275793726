.bar {
  /* Total Style inside the navbar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: white;
  position: sticky;
  top: 0;
  height: 112px;
  z-index: 100;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
}

.links {
  /* Style the links inside the navbar */
  a {
    letter-spacing: 2px;
    font-size: 1rem;

    @media screen and (max-width: 80em) {
      padding: 15px 0;
    }
  }

  .searchInput {
    margin: 16px 0;
  }
}
