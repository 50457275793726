.homeText {
  padding: 80px 0;
  text-align: justify;

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }

  .title {
    padding-bottom: 40px;

    .livets {
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
    }
    .pussel {
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
    }
  }

  .obi {
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
      1px 1px 0 white;
  }
}
